import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home'
import Index from '../views/Index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index 
  },
  {
    path: '/autorizar-compartilhamento',
    name: 'home',
    component: Home
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
