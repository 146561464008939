import axios from 'axios'

const BASE_URL = process.env.VUE_APP_BASE_URL
const PRACAS_URL = process.env.VUE_APP_PRACAS_URL

const OAUTH_URL = process.env.VUE_APP_OAUTH_URL
const OAUTH_USER = process.env.VUE_APP_OAUTH_USER
const OAUTH_PASS = process.env.VUE_APP_OAUTH_PASS


const getSquare = async () => {
  let square = {}
  square.sigla = process.env.VUE_APP_PRACA_FIXA_SIGLA
  square.nome = process.env.VUE_APP_PRACA_FIXA_NOME
  return square;
}

const getSquareList = async () => {

  
  let square = process.env.VUE_APP_PRACA_FIXA_SIGLA
  

  const options = {
    url: `${ PRACAS_URL }`,
    crossDomain: true,
    headers: {
      'Accept': 'application/json',
      "Access-Control-Allow-Origin": "*",
      'x-tenant': square
    },
    method: 'GET',
  }
  
  const response = await axios(options)
    .then(res => res)
    .catch(err => err.response);
  
  debugger;
  
  return response
}

const getCompanyList = async square => {

  let token = await getToken();

  const options = {
    
    url: `${ BASE_URL }/v1/cadastros/empresa/central`,
    crossDomain: true,
    headers: {
      'Accept': 'application/json',
      "Access-Control-Allow-Origin": "*",
      'Authorization': `Bearer ${token}`
    },
    method: 'GET',
  }
  
  const response = await axios(options)
    .then(res => res)
    .catch(err => err.response)
  debugger;
  return response
}

const verifyCard = async (cardNumber, square) => {

  let token = await getToken();
  debugger;
  const options = {
    
    url: `${ BASE_URL }/v1/positivacao/cartao/${ cardNumber }`,
    crossDomain: true,
    headers: {
      'Accept': 'application/json',
      "Access-Control-Allow-Origin": "*",
      'Authorization': `Bearer ${token}`
    },
    method: 'GET',
  }
  
  const response = await axios(options)
    .then(res => res)
    .catch(err => err.response)
  
  return response
}

const getCardInfo = async (cardNumber, square) => {
  let token = await getToken();
  const options = {
    url: `${ BASE_URL }/v1/positivacao/perguntas/cartao/${ cardNumber }`,
    crossDomain: true,
    headers: {
      'Accept': 'application/json',
      "Access-Control-Allow-Origin": "*",
      'Authorization': `Bearer ${token}`
    },
    method: 'GET',
  }
  
  const response = await axios(options)
    .then(res => res)
    .catch(err => err.response)
  
  debugger;
  return response
}

const verifyAnswers = async (data, square) => {

  let token = await getToken();
  
  const options = {
    
    url: `${ BASE_URL }/v1/positivacao/respostas`,
    crossDomain: true,
    headers: {
      'Accept': 'application/json',
      "Access-Control-Allow-Origin": "*",
      'Authorization': `Bearer ${token}`
    },
    method: 'POST',
    data: data,
  }
  
  const response = await axios(options)
    .then(res => res)
    .catch(err => err.response)
  
  debugger;

  return response
}

const submitData = async (data) => {

  debugger;
  let token = await getToken();
  const options = {
    url: `${ BASE_URL }/v1/protocolo/compartilhamento/central`,
    crossDomain: true,
    headers: {
      'Accept': 'application/json',
      "Access-Control-Allow-Origin": "*",
      'Authorization': `Bearer ${ token }`
    },
    method: 'POST',
    data: data,
  }
  
  const response = await axios(options)
    .then(res => res)
    .catch(err => err.response)
  
  return response
}

const getToken = async () => {
  debugger;
  let data = {
    username : OAUTH_USER,
    password: OAUTH_PASS
  }
  const options = {
    url: OAUTH_URL,
    crossDomain: true,
    headers: {
      'Accept': 'application/json',
      "Access-Control-Allow-Origin": "*"      
    },
    method: 'POST',
    data: data,
  }

  const response = await axios(options)
    .then(res => res)
    .catch(err => err.response)
  
  return response.data.access_token;
}

export {
  verifyCard,
  getSquare,
  getSquareList,
  getCompanyList,
  getCardInfo,
  verifyAnswers,
  submitData,
  getToken
  
}
