export default {
    en: {
        next: 'Next',
        back: 'Back',
        finish: 'Finish'
    },
    es: {
        next: 'Siguiente',
        back: 'Atrás',
        finish: 'Finalizar'
    },
    pt: {
        next: 'Avançar',
        back: 'Voltar',
        finish: 'Salvar'
    },
    ja: {
        next: '次へ',
        back: '戻る',
        finish: '完了'
    },
    he: {
        next: 'הבא',
        back: 'חזרה',
        finish: 'סיום'
    },
    cn: {
        next: '下一步',
        back: '返回',
        finish: '完成'
    },
    ru: {
        next: 'Вперед',
        back: 'Назад',
        finish: 'Готово'
    },
    ar: {
        next: 'التالي',
        back: 'السابق',
        finish: 'انهاء'
    },
    nl: {
        next: 'Volgende',
        back: 'Vorige',
        finish: 'Klaar'
    },
    sv: {
        next: 'Nästa',
        back: 'Tillbaka',
        finish: 'Skicka'
    }
}