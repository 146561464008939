<template>
  <div id="app">
    <a href=url">
      <nav class="navbar"></nav>
    </a>
    <div class="subheader">
      <p class="h5 ml-4 text-white">
        Compartilhamento Geral de Dados Pessoais
      </p>
    </div>

    <main class="flex-shrink-0">
      <div class="container mb-5">
        <horizontal-stepper
          :steps="demoSteps"
          @completed-step="completeStep"
          @active-step="isStepActive"
          @stepper-finished="confirmUserAction">
        </horizontal-stepper>

        <b-modal
          id="modal-confirm"
          ref="modal-confirm"
          hide-footer no-close-on-backdrop>

          <template #modal-title>
            Confirmação
          </template>

          <div class="d-block text-center text-muted">
            <b-row class="text-center mt-4">
              <div class="col-md-4 col-sm-12">
                <font-awesome-icon icon="exclamation-circle" size="3x"/>
              </div>

              <div class="col-md-8 col-sm-12 text-md-left mt-4 mt-md-0">
                <p v-if="canShareData == 'S'">
                  Confirma a operação para o compartilhamento dos dados?
                </p>
                <p v-else>
                  Confirma a operação para o não compartilhamento dos dados?
                </p>
              </div>
            </b-row>

            <b-button variant="" ref="submitButton" class="btn-default mt-2 mr-3" @click="setDataAndSubmit">
              <b-spinner
                v-if="loading"
                class="mr-1"
                small
              ></b-spinner>
              Sim
            </b-button>

            <b-button variant="secondary" class="mt-2" @click="closeModal">
              Não
            </b-button>
          </div>
        </b-modal>

        <b-modal id="modal-success" ref="modal-success"
          hide-footer
          no-close-on-backdrop
          hide-header-close
          no-close-on-esc
          size="md">

          <template #modal-title>
            Informação
          </template>

          <div class="d-block text-center text-muted">
            <b-row class="text-center mt-4">
              <div class="col-md-4 col-sm-12 mt-5">
                <font-awesome-icon icon="check-circle" size="4x"/>
              </div>

              <div class="col-md-8 col-sm-12 text-md-left mt-4 mt-md-0">
                <p>
                  Operação realizada com sucesso
                </p>

                <p v-if="protocolo">
                  Protocolo: {{ protocolo }}
                  <br>
                  Código de autenticação: {{ codigoAutenticacao }}
                </p>

                <p class="mt-4">
                  <a href="#" id="download-protocol" @click="downloadProtocol">
                    <font-awesome-icon icon="file-download" class="text-muted mr-2"/>
                    Protocolo desta solicitação
                  </a>

                  <br>

                  <a href="#" id="download-data" @click="downloadPersonalData">
                    <font-awesome-icon icon="file-download" class="text-muted mr-2"/>
                    Dados pessoais mantidos conosco
                  </a>
                </p>

                <b-tooltip triggers="hover" target="download-protocol" placement="right">
                  Clique aqui para fazer o download do arquivo PDF do protocolo desta solicitação
                </b-tooltip>

                <b-tooltip triggers="hover" target="download-data" placement="right">
                  Clique aqui para fazer o download do arquivo PDF dos seus dados pessoais mantidos conosco
                </b-tooltip>
              </div>
            </b-row>

            <b-button variant="" class="btn-default mt-2" @click="reloadPage">
              Ok
            </b-button>
          </div>
        </b-modal>
      </div>
    </main>
  </div>
</template>

<script>
import HorizontalStepper from '../components/HorizontalStepper/HorizontalStepper.vue'

import StepOne from '../components/StepOne.vue'
import StepTwo from '../components/StepTwo.vue'
import StepThree from '../components/StepThree.vue'
import StepFour from '../components/StepFour.vue'
import StepFive from '../components/StepFive.vue'

import { mapActions, mapGetters } from 'vuex'

import { submitData } from '../services/card'

import { getFileProtocol, getPersonalData } from '../services/download'

let praca = process.env.VUE_APP_PRACA_FIXA_SIGLA
let linkHome = 'https://lgpd-'+praca+'.tacom.srv.br'

export default {
  props: {
  },
  name: 'Home',
  components: {
    HorizontalStepper
  },
  data() {
    return {
      demoSteps: [
        {
          name: 'StepOne',
          title: 'Passo 1',
          component: StepOne,
          completed: false

        },
        {
          name: 'StepTwo',
          title: 'Passo 2',
          component: StepTwo,
          completed: false
        },
        {
          name: 'StepThree',
          title: 'Passo 3',
          component: StepThree,
          completed: false
        },
        {
          name: 'StepFour',
          title: 'Passo 4',
          component: StepFour,
          completed: false
        },
        {
          name: 'StepFive',
          title: 'Passo 5',
          component: StepFive,
          completed: false
        }
      ],
      activeStep: 0,
      loading: false,
      message: null,
      codigoExternoCartao: null,
      protocolo: null,
      codigoAutenticacao: null,
      codigoTitular: null,
      linkProtocol: null,
      linkHome
    }
  },
  computed: {
    ...mapActions(['setSubmitData']),
    ...mapGetters(['form', 'dataToSend', 'canShareData']),
    url(){
      return `https://lgpd-${praca}.tacom.srv.br`
    }
  },
  methods: {
    completeStep(payload) {
      this.demoSteps.forEach((step) => {
        if (step.name === payload.name) {
          step.completed = true;
        }
      })
    },
    isStepActive(payload) {
      this.demoSteps.forEach((step) => {
        if (step.name === payload.name) {
          if(step.completed === true) {
            step.completed = false;
          }
        }
      })
    },
    confirmUserAction() {
      this.$refs['modal-confirm'].show()
    },
    setDataAndSubmit: async function () {
      this.loading = true
      this.$refs['submitButton'].disabled = true

      this.$store.dispatch('setSubmitData')

      try {
        let submitDataRequest = await submitData(this.dataToSend, this.form.square)

        if (submitDataRequest.status == 200) {

          sessionStorage.clear()

          this.protocolo = submitDataRequest.data.protocolo
          this.codigoAutenticacao = submitDataRequest.data.codigoAutenticacao
          this.codigoTitular = submitDataRequest.data.codigoTitular

          this.loading = false
          this.$refs['modal-confirm'].hide()
          this.$refs['modal-success'].show()

        } else {

          this.message = submitDataRequest.data.mensagemDeErro
          this.codigoExternoCartao = submitDataRequest.data.codigoExternoCartao

          this.loading = false
          this.$refs['modal-confirm'].hide()
        }
      } catch (e) {
        console.error(e)

        // this.$store.dispatch('setFetchError', true)
        // this.$store.dispatch('setFetchErrorMessage', 'Ocorreu um erro ao enviar as informações. Atualize a página ou tente novamente mais tarde')

        this.closeModal()
      }
    },
    downloadProtocol: async function () {
      try {

        let fileProtocol = await getFileProtocol(
          this.form.cardNumber,
          this.codigoTitular,
          this.form.square,
        )

        if (fileProtocol.status == 200) {
          
          let fileType = fileProtocol.headers['content-type']

          let fileURL = window.URL.createObjectURL(
            new Blob([fileProtocol.data]),
            { type: fileType }
          )

          let link = document.createElement('a')

          link.href = fileURL
          link.download = 'protocolo.pdf'
          link.click()

        } else {

          this.message = fileProtocol.data.mensagemDeErro

          this.loading = false
          this.$refs['modal-confirm'].hide()
        }
      
      } catch (e) {
        console.error(e)

        // this.$store.dispatch('setFetchError', true)
        // this.$store.dispatch('setFetchErrorMessage', 'Ocorreu um erro ao buscar protocolo. Atualize a página ou tente novamente mais tarde')
      }
      
    },
    downloadPersonalData: async function () {
      try {

        let filePersonalData = await getPersonalData(
          this.form.cardNumber,
          this.codigoTitular,
          this.form.square
        )

        if (filePersonalData.status == 200) {

          let fileType = filePersonalData.headers['content-type']

          let fileURL = window.URL.createObjectURL(
            new Blob([filePersonalData.data]),
            { type: fileType }
          )

          let link = document.createElement('a')

          link.href = fileURL
          link.download = 'dados-pessoais.pdf'
          link.click()

        } else {

          this.message = filePersonalData.data.mensagemDeErro

          this.loading = false
          this.$refs['modal-confirm'].hide()
        }
      
      } catch (e) {
        console.error(e)

        // this.$store.dispatch('setFetchError', true)
        // this.$store.dispatch('setFetchErrorMessage', 'Ocorreu um erro ao buscar arquivo com dados pessoais. Atualize a página ou tente novamente mais tarde')
      }
      
    },
    reloadPage() {
      sessionStorage.clear()
      this.$router.push({ name: 'index' })
    },
    closeModal() {
      this.$refs['modal-confirm'].hide()
    }
  }
}
</script>

<style scoped>
main {
  margin-top: 5rem;
}

.pointer {
  cursor: pointer;
}

h1, h2 {
  font-weight: normal;
}

hr {
  background-color: transparent;
  border: none;
  display: block;
  height: inherit;
  margin: 1.5rem 0;
  border-top: dashed 1px;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #0b99b9;
  text-decoration: underline;
}

.text-medium-grey {
  color: #333;
}

.text-light-grey {
  color: #888;
}

.box.formated {
  position: relative;
  padding: 0;
}

.box.formated .heading {
  font-size: 1rem;
  text-transform: capitalize;
  padding: .8rem 1.5rem;
  background-color: #fafafa;
}

.box.formated .content {
  padding: 1rem 2rem;
}

i.top-left {
  position: absolute;
  left: 1.5rem;
  top: 0.8rem;
}

.vertical-separator {
  display: flex;
  justify-content: space-around;
}

.vertical-separator .line {
  border-right: 1px solid #cccccc;
}

.btn-default {
  color: #fff !important;
  border-color: #e3774a !important;
  background: linear-gradient(#f4974c, #e3774a) !important;
}
</style>