<template>
  <div>
    <nav class="navbar"></nav>
    <div class="subheader">
      <p class="h5 ml-4 text-white">
        Compartilhamento Geral de Dados Pessoais
      </p>
    </div>

    <div class="px-4 py-2 my-5 text-center">
      <font-awesome-icon icon="lock" size="5x" class="text-muted mb-5"/>

      <div class="col-sm-12 col-lg-6 mx-auto">
        <p class="mb-4 text-justify">
          Como já amplamente divulgado, o presidente Jair Bolsonaro sancionou a lei nº 13.709/2018,
          com isso, a LGPD (Lei Geral de Proteção de Dados).

          <br>
          <br>

          Trata-se de uma nova lei brasileira, que tem como principal objetivo garantir a transparência e
          regulamentação no tratamento dos dados pessoais.

          <br>
          <br>

          Diante disso, nós, da TACOM, nos adequamos à LGPD, seguindo as devidas premissas para
          atingir o compliance com as melhores práticas do mercado. Nosso objetivo primordial é ter a
          transparência de que os dados pessoais serão tratados com respeito e segurança, em
          cumprimento às normas legais vigentes no Brasil.

          <br>
          <br>

          A LGPD regulamenta vários aspectos sobre a privacidade de dados pessoais, inclusive entre
          empresas, colaboradores, parceiros, terceirizados e autônomos.

          <br>
          <br>

          Para atendê-la, a TACOM está constantemente revendo seus processos internos, ajustando
          os termos dos contratos com clientes, funcionários e fornecedores, realizando uma auditoria
          de acesso a nossas bases dados, além de adotar procedimentos que garantam a segurança, o
          sigilo e a confidencialidade das informações armazenadas e tratadas por nossos softwares.

          <br>
          <br>

          A TACOM reforça seu compromisso irrestrito e incondicional ao direito, privacidade e
          proteção dos dados dos titulares, aliados a transparência e regulamentação no tratamento dos
          dados.
        </p>

        <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
          <router-link :to="{ name: 'home' }" class="btn btn-default px-4 me-sm-3">
            Acessar compartilhamento de dados
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data() {
    return {}
  }
}
</script>

<style>
.btn-default {
  color: #fff !important;
  border-color: #e3774a !important;
  background: linear-gradient(#f4974c, #e3774a) !important;
}

.navbar {
  height: 3.8rem;
  background-position: left;
  background-repeat: no-repeat;
  background-color: #703827 !important;
  background-image: url('../assets/cabecalho.jpg') !important;
}

.subheader {
  min-height: 3.2rem;
  padding-top: 0.9rem;
  padding-bottom: 0.4rem;
  background: #58748c !important;
}

.subheader p {
  color: #fff !important;
  font-weight: 700 !important;
}

@media (min-width: 1024px) {
  .subheader {
    padding-left: 0.6rem !important;
  }
}
</style>