<template>
  <b-modal id="modal-terms" title="Termos de uso" scrollable ok-only no-close-on-backdrop ok-title="Fechar" size="xl">
    <p class="my-4 text-justify">
      Ao utilizar o  ou website {{ company }}, você automaticamente concorda com estes Termos de Uso, responsabilizando-se integralmente por todos e quaisquer atos praticados por você no  website {{ company }} ou nas propriedades físicas da {{ company }} ou em serviços a ele relacionados. Caso você não concorde com qualquer dos termos e condições abaixo estabelecidos, você não deve utilizar  website {{ company }}, tampouco contratar com a {{ company }} a locação do espaço. 

      <br>
      <br>

      <b>1.	O QUE É O WEBSITE {{ company }}?</b>
      <br>1.1. Serviços. O website {{ company }} é uma plataforma que oferece os seguintes serviços: Fornecimento de informações sobre a {{ company }} e seus produtos, acesso à página de login nos sistemas da {{ company }} e cadastro para manifestar interesse nos produtos da {{ company }}.
      <br>1.2. Suspensão. Nós nos reservamos o direito de suspender ou cancelar, a qualquer momento, o seu acesso ao website em caso de suspeita de fraude, obtenção de benefício ou vantagem de forma ilícita, ou pelo não cumprimento de quaisquer condições previstas nestes Termos de Uso, na Política de Privacidade ou na legislação aplicável. Nesses casos, não será devida qualquer indenização a você e a {{ company }} poderá promover a competente ação de regresso, se necessário, bem como tomar quaisquer outras medidas necessárias para perseguir e resguardar seus interesses.

      <br><br>
      <b>2.	PROMOÇÕES</b>
      <br>2.1 O website {{ company }} pode, a qualquer tempo, disponibilizar publicidades ou apresentar ofertas promocionais.
      <br>2.2 Sua participação em qualquer promoção ou oferta está sujeita às regras aplicáveis associadas à promoção.
      
      <br><br>
      <b>3.	QUAIS SÃO OS DIREITOS DA {{ company }} SOBRE O WEBSITE?</b>
      <br>3.1. Nossos Direitos. Todos os direitos relativos ao website {{ company }} e suas funcionalidades são de propriedade exclusiva da {{ company }}, inclusive no que diz respeito aos seus textos, imagens, layouts, software, códigos, bases de dados, gráficos, artigos, fotografias e demais conteúdos produzidos direta ou indiretamente pela {{ company }} (“Conteúdo do website {{ company }}”). O Conteúdo do website {{ company }} é protegido pela lei de direitos autorais e de propriedade intelectual. É proibido usar, copiar, reproduzir, modificar, traduzir, publicar, transmitir, distribuir, executar, fazer o upload, exibir, licenciar, vender ou explorar e fazer engenharia reversa do conteúdo do website {{ company }}, para qualquer finalidade, sem o consentimento prévio e expresso da {{ company }}. Qualquer uso não autorizado do Conteúdo do website {{ company }} será considerado como violação dos direitos autorais e de propriedade intelectual da {{ company }}.
      
      <br><br>
      <b>4.	PROPRIEDADE INTELECTUAL SOBRE O SOFTWARE E OS MATERIAIS DISPONIBILIZADOS</b>
      <br>4.1. Propriedade Intelectual. Para nós da {{ company }}, a qualidade dos materiais disponibilizados ao usuário é de suma importância. A criação deles é fruto de muito trabalho e dedicação de nossos desenvolvedores. Por isso, rearmamos que a {{ company }} garante que todos os direitos, título e interesse (incluindo os direitos autorais, e outros de propriedade intelectual) sobre o website {{ company }} permanecerão sob a titularidade da {{ company }}.
      <br>4.2. Não-aquisição de Direitos. O usuário não adquirirá nenhum direito de propriedade sobre os serviços e conteúdos do website {{ company }}, exceto quando haja outorga expressa neste Termos de Uso.

      <br><br>
      <b>5.	O QUE MAIS EU PRECISO SABER SOBRE ESTES TERMOS DE USO?</b>
      <br>5.1. Alterações. Para melhorar sua experiência, o website {{ company }} está sempre sendo atualizado. Por esse motivo os Termos de Uso podem ser alterados, a qualquer tempo, a fim de refletir os ajustes realizados. Caso você não concorde com os novos Termos de Uso, você poderá rejeitá-los, mas, infelizmente, isso significa que você não poderá mais ter acesso e fazer uso do website {{ company }}. Se de qualquer maneira você utilizar o website {{ company }} mesmo após a alteração destes Termos de Uso, isso significa que você concorda com todas as modificações.
      <br>5.2. Conflito entre Disposições. Em caso de conflito entre estes termos e os termos modificados, os termos posteriores prevalecerão com relação a esse conflito.
    </p>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalTermsOfUse',
  props: ['company'],
  data() {
    return {}
  },
}
</script>
