import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    form: {
      square: null,
      cardNumber: null,
      squareName: null,
    },
    canShareData: null,
    acceptTerms: null,
    acceptPolicy: null,
    firstQuestion: {},
    secondQuestion: {},
    thirdQuestion: {},
    dataToValidate: {},
    messageStatus: null,
    dataToSend: {
      "numeroCartao": "",
      "aceitaCompartilharDados": "",
      "aceiteTermosUso": "",
    },
    hasChange: false,
    fetchError: false,
    fetchErrorMessage: ""
  },
  getters: {
    form(state) {
      return state.form
    },
    firstQuestion(state) {
      return state.firstQuestion
    },
    secondQuestion(state) {
      return state.secondQuestion
    },
    thirdQuestion(state) {
      return state.thirdQuestion
    },
    canShareData(state) {
      return state.canShareData
    },
    acceptTerms(state) {
      return state.acceptTerms
    },
    acceptPolicy(state) {
      return state.acceptPolicy
    },
    dataToValidate(state) {
      return state.dataToValidate
    },
    dataToSend(state) {
      return state.dataToSend
    },
    messageStatus(state) {
      return state.messageStatus
    },
    hasChange(state) {
      return state.hasChange
    },
    fetchError(state) {
      return state.fetchError
    },
    fetchErrorMessage(state) {
      return state.fetchErrorMessage
    }
  },
  mutations: {
    SET_FORM(state, value) {
      state.form = value
    },
    SET_SQUARE_NAME(state, value) {
      state.form.squareName = value
    },
    SET_SELECTED_FIRST_QUESTION(state, value) {
      state.firstQuestion.selected = value
    },
    SET_SELECTED_SECOND_QUESTION(state, value) {
      state.secondQuestion.selected = value
    },
    SET_SELECTED_THIRD_QUESTION(state, value) {
      state.thirdQuestion.selected = value
    },
    SET_CAN_SHARE_DATA(state, value)  {
      state.canShareData = value
    },
    SET_ACCEPT_TERMS(state, value)  {
      state.acceptTerms = value
    },
    SET_ACCEPT_POLICY(state, value) {
      state.acceptPolicy = value
    },
    SET_QUESTIONS(state, arr) {
      let questions = ['firstQuestion', 'secondQuestion', 'thirdQuestion']

      for (let index = 0; index < arr.length; index++) {
        const element = arr[index]
        state[questions[index]] = element
      }
    },
    SET_MESSAGE_STATUS(state, value) {
      state.messageStatus = value
    },
    SET_VALIDATE_DATA(state) {
      state.dataToValidate.numeroCartao = state.form.cardNumber
      state.dataToValidate.respostas = [
        {"tipo": state.firstQuestion.tipo, "id": state.firstQuestion.selected},
        {"tipo": state.secondQuestion.tipo, "id": state.secondQuestion.selected},
        {"tipo": state.thirdQuestion.tipo, "id": state.thirdQuestion.selected}
      ]

      state.hasChange = false
    },
    SET_SUBMIT_DATA(state) {
      state.dataToSend.numeroCartao = state.form.cardNumber
      state.dataToSend.aceitaCompartilharDados = state.canShareData
      state.dataToSend.aceiteTermosUso = state.acceptTerms
    },
    RESET_STATE(state) {
      state.canShareData = null
      state.acceptTerms = null
      state.firstQuestion = {}
      state.secondQuestion = {}
      state.thirdQuestion = {}
      state.dataToSend = {}
    },
    RESET_FORM(state) {
      state.form.square = null
      state.form.cardNumber = null
      state.form.squareName = null
    },
    SET_CHANGE(state, value) {
      state.hasChange = value
    },
    SET_FETCH_ERROR(state, value) {
      state.fetchError = value
    },
    SET_FETCH_ERROR_MESSAGE(state, value) {
      state.fetchErrorMessage = value
    }
  },
  actions: {
    setForm({ commit }, form) {
      commit('SET_FORM', form)
    },
    setSquareName({ commit }, squareName) {
      commit('SET_SQUARE_NAME', squareName)
    },
    setSelectedFirstQuestion({ commit }, selected) {
      commit(
        'SET_SELECTED_FIRST_QUESTION', selected
      )
    },
    setSelectedSecondQuestion({ commit }, selected) {
      commit(
        'SET_SELECTED_SECOND_QUESTION', selected
      )
    },
    setSelectedThirdQuestion({ commit }, selected) {
      commit(
        'SET_SELECTED_THIRD_QUESTION', selected
      )
    },
    setCanShareData({ commit }, value)  {
      commit(
        'SET_CAN_SHARE_DATA', value
      )
    },
    setAcceptTerms({ commit }, value)  {
      commit(
        'SET_ACCEPT_TERMS', value
      )
    },
    setAcceptPolicy({ commit }, value) {
      commit(
        'SET_ACCEPT_POLICY', value
      )
    },
    setQuestions({ commit }, arr) {
      commit(
        'SET_QUESTIONS', arr
      )
    },
    setMessageStatus({ commit }, value) {
      commit('SET_MESSAGE_STATUS', value)
    },
    setValidateData({ commit }) {
      commit('SET_VALIDATE_DATA')
    },
    setSubmitData({ commit }) {
      commit('SET_SUBMIT_DATA')
    },
    resetState({ commit }) {
      commit('RESET_STATE')
    },
    resetForm({ commit }) {
      commit('RESET_FORM')
    },
    setChange({ commit }, value) {
      commit('SET_CHANGE', value)
    },
    setFetchError({ commit }, value) {
      commit('SET_FETCH_ERROR', value)
    },
    setFetchErrorMessage({ commit }, value) {
      commit('SET_FETCH_ERROR_MESSAGE', value)
    }
  }
})