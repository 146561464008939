<template>
  <b-modal id="privacy-policy" title="Política de privacidade" scrollable ok-only no-close-on-backdrop ok-title="Fechar" size="xl">
    <p class="my-4 text-justify">
      A presente Política de Privacidade (“Política”) explica de maneira clara e acessível como as suas informações e dados serão coletados, usados, compartilhados e armazenados por meio dos nossos sistemas.
      A aceitação da nossa Política será feita quando você acessar o website da {{ company }}. Isso indicará que você está ciente e em total acordo com a forma como utilizaremos as suas informações e seus dados, estando de acordo com a coleta de dados para serem utilizados pela nossa empresa.
      Há ainda, desde que autorizado, a possibilidade de enviar e-mails de Marketing, o que permite com que você receba notícias sobre nossos produtos e outras atualizações, podendo haver a retirada do consentimento a qualquer tempo caso você mude de ideia.
      A presente Política está dividida da seguinte forma para facilitar a sua compreensão:
      
      <br>
      <br>
      
      <b>1. INFORMAÇÕES QUE COLETAMOS</b>
      <br>
      Nós coletamos os seguintes tipos de informações:

      <br><br>
      1.1. Informações que você nos fornece.
      
      <br><br>
      1.2. Informações geradas quando você usa nosso website {{ company }} nós coletamos as seguintes informações geradas:
      <br>•	Registros de acesso. O website {{ company }} coleta automaticamente registros de acesso a aplicação, que incluem o endereço IP, com data e hora, utilizado para acessar o website {{ company }}. Esses dados são de coleta obrigatória, de acordo com a Lei 12.965/2014, mas somente serão fornecidos para terceiros com a sua autorização
      <br>•	Comunicações com o website {{ company }}. Quando você se comunica com o website {{ company }}, nós podemos coletar informações sobre sua comunicação, incluindo metadados como data, IP e hora das comunicações.
      <br>•	Comunicações com dados de acesso: servem para confirmação da identidade do usuário e englobam, por exemplo, respostas às perguntas que faremos para, de alguma forma, identifica-lo e outras informações de segurança.

      <br><br>
      
      <b>2. COMO USAMOS SUAS INFORMAÇÕES</b>
      <br>
      Não custa lembrar, prezamos muito pela sua privacidade. Por isso, todos os dados e informações sobre você são tratadas como confidenciais e somente as usaremos para os fins aqui descritos e autorizados por você, principalmente para que você possa utilizar o website {{ company }} de forma plena, visando sempre melhorar a sua experiência como usuário.
      <br><br>
      2.1. Usos autorizados Desta forma, poderemos utilizar seus dados para:
      <br>•	Permitir que você acesse e utilize todas as funcionalidades do website {{ company }};
      <br>•	Realização de processos de prevenção à fraude, execução de atividades de relacionamento e atendimento ao cliente; cumprimento de obrigações legais e regulatórias;
      <br>•	Analisar o tráfego dos usuários em nossas aplicações;
      <br>•	Detecção e prevenção de fraudes, spam e incidentes de segurança;
      <br>•	Cumprir obrigações legais.

      <br><br>
      2.2. Exclusão dos dados
      Todos os dados coletados serão excluídos de nossos servidores quando você assim requisitar, por procedimento gratuito e facilitado, ou quando estes não forem mais necessários ou relevantes para lhe oferecermos os nossos serviços, salvo se houver qualquer outra razão para a sua manutenção, como eventual obrigação legal de retenção de dados ou necessidade de preservação destes para resguardo de direitos do website {{ company }}.
      Nos casos em que você solicite a exclusão dos seus dados, ela será feita prontamente, na medida do que for possível.
      <br><br>
      2.3. Monitoramento
      A {{ company }} se reserva no direito de monitorar toda a plataforma, principalmente para assegurar que as regras descritas em nosso Termos de Uso estão sendo observadas, ou ainda se não há violação ou abuso das leis aplicáveis.
      <br><br>
      2.4. Exclusão de usuário
      A {{ company }} se reserva no direito de excluir determinado usuário, independentemente do tipo que for, caso a presente Política ou os Termos de Uso não sejam respeitados. Como prezamos pelo bom relacionamento com os usuários, reconhecemos que têm o direito de buscar entender os motivos e até contestá-los. 
      <br><br>
      2.5. Sites e Aplicativos de Terceiros
      Os serviços e produtos da {{ company }} podem ser contratados e/ou prestados por meio de diferentes aplicativos, sistemas operacionais ou plataformas, inclusive de terceiros.
      Nesses casos, as informações que você concordou espontaneamente em disponibilizar serão coletadas pelo provedor e repassadas a {{ company }}, como, por exemplo, informações cadastrais, para fins de verificação e garantia de acesso, e informações de consumo, para melhoria contínua da experiência de quem utiliza nossas plataformas.
      A {{ company }} recebe as informações conforme as políticas de privacidade do provedor.
      Assim, é recomendável que você consulta as respectivas políticas de privacidade dos websites e aplicativos do provedor para se informar adequadamente a respeito do uso de suas informações pessoais e, caso não esteja de acordo, possa verificar a existência de recursos disponibilizados pelo provedor para controle de sua privacidade.
      <br><br>
      2.6. Dados de menores
      A {{ company }} pode realizar a coleta e o tratamento de dados pessoais de menores de idade (pessoas com idade inferior a 18 anos) nos casos de dependentes, beneficiários ou clientes.
      Dados do menor, como nome completo e CPF, são coletados mediante consentimento específico e em destaque por, pelo menos, um dos pais ou responsável legal e são utilizados para garantir o acesso contínuo aos serviços e produtos.
      Lá, os pais e/ou responsável legal poderão obter uma cópia das informações e realizar controles de acesso.
      Vale ressaltar ainda que a {{ company }} não utiliza dados de menores para fins de marketing e/ou oferta de produtos e serviços.

    <br><br>

      <b>3. COMPARTILHAMENTO DAS INFORMAÇÕES</b>
      <br>
      A {{ company }} poderá compartilhar as informações pessoais constantes em sua base de dados com outras empresas do seu grupo econômico, prestadores de serviços ou ainda parceiros de negócios, desde que seja para a execução de atividades contratuais, se houver o consentimento do titular ou o legítimo interesse da {{ company }} e as informações forem relevantes para viabilizar e/ou aprimorar os produtos e serviços aos usuários e sejam protegidas e resguardadas por quem as recebeu.
      <br>
      Além das hipóteses previstas acima, a {{ company }} poderá compartilhar dados pessoais com entidades de proteção ao crédito, prevenindo situações de fraude, e também por ordem judicial e/ou determinação legal ou regulatória, sendo que nesses casos não será necessária a coleta do consentimento do usuário para isso.
      Dessa forma, a {{ company }} NÃO VENDE SUAS INFORMAÇÕES PESSOAIS apenas publica ou compartilha com terceiros, informações não identificáveis, ou seja, que não há a possibilidade de associação, direta ou indireta, a um indivíduo.
      Todos os dados, informações e conteúdos sobre você podem ser considerados ativos no caso de negociações em que a {{ company }} fizer parte. Portanto, nos reservamos no direito de, por exemplo, incluir seus dados dentre os ativos da empresa caso esta venha a ser vendida, adquirida ou fundida com outra. Por meio desta Política você concorda e está ciente desta possibilidade.
      A {{ company }} se reserva no direito de fornecer seus dados e informações sobre você, incluindo interações suas, caso seja requisitado judicialmente para tanto, ato necessário para que a empresa esteja em conformidade com as leis nacionais, ou caso você autorize expressamente.
      
      <br><br>

      <b>4. DIREITOS DOS TITULARES</b>
      <br>
      Você sempre poderá optar em não divulgar seus dados para nós, mas tenha em mente que alguns desses dados podem ser necessários para utilizar as funcionalidades de nosso website. Independente disso, você sempre possuirá direitos relativos à privacidade e à proteção dos seus dados pessoais.
      Dessa forma, abaixo resumimos todos os direitos que você tem sob as leis setoriais brasileiras relativas a proteção de dados e a Lei Geral de Proteção de Dados (LGPD), quais sejam:
      <br><br>4.1. Direito de acesso. Este direito permite que você possa requisitar e receber uma cópia dos dados pessoais que possuímos sobre você.
      <br><br>4.2. Direito de retificação. Este direito permite que você, a qualquer momento, possa solicitar a correção e/ou retificação dos seus dados pessoais, caso identifique que que alguns deles estão incorretos. Contudo, para ser efetivada essa correção, teremos que checar a validade dos dados que você nos fornece.
      <br><br>4.3. Direito de exclusão. Este direito permite que você possa nos solicitar a exclusão dos dados pessoais que possuímos sobre você. Todos os dados coletados serão excluídos de nossos servidores quando você assim requisitar ou quando estes não forem mais necessários ou relevantes para lhe oferecermos nossos serviços, salvo se houver qualquer outra razão para a sua manutenção, como eventual obrigação legal de retenção de dados ou necessidade de preservação destes para resguardo de direitos da website {{ company }}.
      <br><br>4.4. Direito de oposição ao processamento. Você também tem o direito de contestar onde e em que contexto estão tratando seus dados pessoais para diferentes finalidades. Em determinadas situações, podemos demonstrar que temos motivos legítimos para tratar seus dados, os quais se sobrepõem aos seus direitos, caso, por exemplo, sejam essenciais para o fornecimento de nossas aplicações.
      <br><br>4.5. Direito de solicitar anonimização, bloqueio ou eliminação. Este direito permite que você nos peça para suspender o processamento de seus dados pessoais nos seguintes cenários: (a) se você quiser que nós estabeleçamos a precisão dos dados; (b) quando você precisar que sejam mantidos os dados mesmo se não precisarmos mais deles, conforme necessário, para estabelecer, exercer ou defender reivindicações legais; ou (c) se você se opôs ao uso de seus dados, mas nesta hipótese precisamos verificar se temos motivos legítimos para usá-los.
      <br><br>4.6. Direito à portabilidade. Forneceremos a você, ou a terceiros que você escolheu, seus dados pessoais em formato estruturado e interoperável.
      <br><br>4.7. Direito de retirar o seu consentimento. Você tem o direito de retirar o seu consentimento em relação aos termos desta Política de Privacidade. No entanto, isso não afetará a legalidade de qualquer processamento realizado anteriormente. Se você retirar o seu consentimento, talvez não possamos fornecer determinados serviços. A retirada do consentimento pode ser efetuada a qualquer momento. 
      <br><br>4.8. Direito à revisão de decisões automatizadas. Você também tem o direito de solicitar a revisão de decisões tomadas unicamente com base em tratamento automatizado de seus dados pessoais que afetem seus interesses, incluídas as decisões destinadas a definição de perfis pessoais, profissionais, de consumo e de crédito e/ou os aspectos de sua personalidade.
      Talvez seja necessário solicitar informações específicas suas para nos ajudar a confirmar sua identidade e garantir seu direito de acessar seus dados pessoais (ou de exercer seus outros direitos). Esta é uma medida de segurança para garantir que os dados pessoais não sejam divulgados a qualquer pessoa que não tenha direito de recebê-los. Podemos também contatá-lo para obter mais informações em relação à sua solicitação, a m de acelerar nossa resposta. Tentamos responder a todas as solicitações legítimas dentro de 5 dias úteis. Ocasionalmente, pode levar mais de 5 dias se sua solicitação for particularmente complexa ou se você tiver feito várias solicitações. Neste caso, iremos comunicá-lo e mantê-lo atualizado sobre o andamento da sua solicitação.
      Importante salientar que as informações pessoais podem ser divulgadas caso sejamos obrigadas por lei a fazê-lo.
      Ainda, nós fornecemos links para sites de terceiros como uma conveniência para o usuário. Estes links não constituem um endosso ou encaminhamento para os sites ligados. Os sites vinculados têm separados e independentes políticas de privacidade, avisos e termos de uso. Não temos nenhum controle sobre esses sites e, portanto, não temos nenhuma responsabilidade da maneira pela qual as organizações que operam esses sites vinculados podem coletar, usar ou divulgar, proteger ou tratar os dados pessoais. Nós encorajamos a você ler a política de privacidade de cada site que você visita.
      
      <br><br>
      
      <b>5. SEGURANÇA DAS INFORMAÇÕES</b>
      <br>
      Todos os seus dados são confidenciais e somente as pessoas com as devidas autorizações terão acesso a eles. Qualquer uso destes estará de acordo com a presente Política. A {{ company }} empreenderá todos os esforços razoáveis de mercado para garantir a segurança dos nossos sistemas e dos seus dados através do uso do website. Nossos servidores estão localizados em diferentes locais para garantir estabilidade e segurança e somente podem ser acessados por meio de canais de comunicação previamente autorizados.
      Todas as suas informações serão, sempre que possível, criptografadas, caso não inviabilizem o seu uso pela plataforma. A qualquer momento você poderá requisitar cópia dos seus dados armazenados em nossos sistemas. Manteremos os dados e informações somente até quando estas forem necessárias ou relevantes para as finalidades descritas nesta Política, ou em caso de períodos pré-determinados por lei, ou até quando estas forem necessárias para a manutenção de interesses legítimos da {{ company }}.
      A {{ company }} considera a sua privacidade algo extremamente importante e fará tudo que estiver ao alcance para protegê-la. Todavia, não temos como garantir que todos os dados e informações sobre você em nossa plataforma estarão livres de acessos não autorizados, principalmente caso haja compartilhamento indevido das credenciais necessárias para acessar o nosso aplicativo. Portanto, você é o único responsável por manter sua senha de acesso em local seguro e é vedado o compartilhamento desta com terceiros. Você se compromete a notificar a {{ company }} imediatamente, através de meio seguro, a respeito de qualquer uso não autorizado de sua conta, bem como o acesso não autorizado por terceiros a esta.
      Portanto, para proteger suas informações pessoais, tomamos precauções relevantes para garantir a inacessibilidade dessas, bem como que não haja acesso, divulgação, alteração ou destruição dessas informações. Exemplo disso ocorre com as informações transmitidas por cartão de crédito, as quais são armazenadas com uma criptografia.
      
      <br><br>

      <b>6. ARMAZENAMENTO DE DADOS PESSOAIS</b>
      <br>
      A {{ company }} armazena e trata os dados pessoais coletados em local seguro, muitas vezes em servidores próprios da {{ company }} ou por meio de tecnologia cloud, visando sempre as melhorias nos processos e na entrega dos produtos e serviços.
      Conforme previsto nessa Política de Privacidade, nos registros de utilização dos serviços e na legislação vigente e aplicável, os prazos de armazenamento serão:
      i. para os registros de aplicação, que são as informações de perfil e conteúdo compartilhados nos sites e aplicativos próprios da  {{ company }}: [TEMPO] meses;
      ii. para quaisquer tipos de informações coletadas, que poderão ser utilizadas para fins jurídicos ou solicitadas por autoridades públicas de acordo com o prazo de retenção legal e/ou regulatória, podendo variar entre 5 a 20 anos.
      
      <br><br>

      <b>7. SEGURANÇA DOS DADOS PESSOAIS</b>
      <br>
      A {{ company }} utiliza:
      Soluções e medidas técnicas de segurança apropriadas para garantir a confidencialidade, integridade e inviolabilidade dos dados, tais como antivírus, firewall, proteção de rede, encriptação e outras medidas técnicas e de processos minimamente compatíveis com os padrões internacionais e ao uso de boas práticas de mercado;
      Medidas de segurança apropriadas aos riscos, como contra destruição acidental ou ilegal ou perda acidental, alteração, divulgação ou acesso não autorizado;
      <br>
      Controles de acesso às informações armazenadas, delimitando a permissão e os privilégios de acesso de acordo com as responsabilidades envolvidas.
      
      <br><br>
      
      <b>8. ATUALIZAÇÕES DA POLÍTICA DE PRIVACIDADE</b>
      <br>
      A {{ company }} se reserva no direito de alterar essa Política quantas vezes forem necessárias e a qualquer momento, visando fornecer a você mais segurança, conveniência, e melhorar cada vez mais a sua experiência. É por isso que é muito importante acessar nossa Política periodicamente. Para facilitar, indicamos no início do documento a data da última atualização. Caso sejam feitas alterações relevantes que ensejem novas autorizações suas, publicaremos uma nova Política de Privacidade, sujeita novamente ao seu consentimento.
      
      <br><br>
      
      <b>9. LEI APLICÁVEL</b>
      <br>
      Este documento é regido e deve ser interpretado de acordo com as leis da República Federativa do Brasil. Fica eleito o Foro da Comarca de Belo Horizonte, MG, como o competente para dirimir quaisquer questões porventura oriundas do presente documento, com expressa renúncia a qualquer outro, por mais privilegiado que seja.
    </p>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalPrivacyPolicy',
  props: ['company'],
  data() {
    return {}
  },
}
</script>
