<template>
  <b-alert
    fade
    dismissible
    variant="danger"
    :show="hasFetchError"
    @dismissed="hasFetchError=false"
  >

  {{ message }}
  </b-alert>
</template>

<script>
export default {
  name: 'ModalErrorHandler',
  props: ['message', 'hasFetchError'],
  data() {
    return {}
  }
}
</script>