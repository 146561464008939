<template>
  <div class="row">
    <div class="container">
      <div class="col-sm-12 col-md-10 offset-md-1 mt-5">

        <div class="container">
          <ModalErrorHandler
            :hasFetchError="fetchError"
            :message="fetchErrorMessage"/>
        </div>

        <form class="container">
          <b-alert show variant="primary" class="flex-row alert-card-info">
            <p class="ml-2">
              
              <br>
              Número do cartão: <b>{{ form.cardNumber }}</b>
            </p>
          </b-alert>

          <b-alert
            show
            v-if="messageStatus != 'Compartilhamento ainda não realizado'"
            variant="info"
            class="flex-row alert-centered mb-5">
              <b-icon icon="info-circle" font-scale="1.4" class="mr-3"></b-icon>
              {{ this.messageStatus }}
          </b-alert>

          <div class="mt-4 control radio-list">
            <b-form-group label="Deseja autorizar o compartilhamento de dados pessoais?">
              <b-form-radio
                value="S"
                name="can-share-data"
                v-model="$v.canShareData.$model">
                Sim
              </b-form-radio>

              <b-form-radio
                value="N"
                name="can-share-data"
                class="ml-4"
                v-model="$v.canShareData.$model">
                Não
              </b-form-radio>

              <b-form-invalid-feedback
                :state="this.$v.canShareData.$dirty ? !this.$v.canShareData.$invalid : null">
                  Você deve escolher uma das opções para continuar
              </b-form-invalid-feedback>
            </b-form-group>
          </div>

          <div class="mt-4 mb-4">
            <div class="d-flex flex-row align-items-center">
              <b-form-checkbox
                id="checkbox"
                v-model="$v.acceptTerms.$model"
                name="checkbox"
                value="S"
                unchecked-value="N"
              >

              Aceitar os termos de uso
              </b-form-checkbox>

              <a href="#" v-b-modal.modal-terms>
                <font-awesome-icon icon="question-circle" class="text-muted ml-2"/>
              </a>
            </div>

            <div class="d-flex flex-row align-items-center mt-1">
              <b-form-checkbox
                id="acceptPolicy"
                v-model="$v.acceptPolicy.$model"
                name="acceptPolicy"
                value="S"
                unchecked-value="N"
              >

              Aceitar a política de privacidade
              </b-form-checkbox>

              <a href="#" v-b-modal.privacy-policy>
                <font-awesome-icon icon="question-circle" class="text-muted ml-2"/>
              </a>
            </div>
          </div>

          <b-form-invalid-feedback
            :state="this.$v.acceptTerms.$dirty ? !this.$v.acceptTerms.$invalid : null">
              Você deve aceitar os termos para continuar
          </b-form-invalid-feedback>

          <b-form-invalid-feedback
            :state="this.$v.acceptPolicy.$dirty ? !this.$v.acceptPolicy.$invalid : null">
              Você deve aceitar a política de privacidade para continuar
          </b-form-invalid-feedback>
        </form>
      </div>
    </div>

    <ModalTermsOfUse v-bind:company="company.nome"/>
    <ModalPrivacyPolicy v-bind:company="company.nome"/>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

import ModalPrivacyPolicy from './ModalPrivacyPolicy/ModalPrivacyPolicy'
import ModalTermsOfUse from './ModalTermsOfUse/ModalTermsOfUse'

import { getCompanyList, getSquare } from '../services/card'

import ModalErrorHandler from './ModalErrorHandler/ModalErrorHandler'

const validateAcceptTerms = value => value != 'S' ? false : true
const validateAcceptPolicy = value => value != 'S' ? false : true

export default {
  name: 'StepFive',
  props: ['currentStep'],
  mixins: [validationMixin],
  components: {
    ModalPrivacyPolicy,
    ModalTermsOfUse,
    ModalErrorHandler
  },
  data() {
    return {
      company: {}
    }
  },
  validations: {
    canShareData: {
      required
    },
    acceptTerms: {
      required,
      validateAcceptTerms
    },
    acceptPolicy: {
      required,
      validateAcceptPolicy
    }
  },
  methods: {
    toggleCanShareData(value) {
      if (value != this.canShareData) {
        this.$store.dispatch('setCanShareData', value)
      }
    },
    getData: async function() {
      try {
        let square = await getSquare()
        let companyList = await getCompanyList(square)
        this.company = companyList.data

      } catch (e) {
        console.error(e)

        this.$store.dispatch('setFetchError', true)
        this.$store.dispatch(
          'setFetchErrorMessage',
          'Ocorreu um erro ao buscar informações de termos de uso e política de privacidade. Atualize a página ou tente novamente mais tarde')
      }
    },
  },
  computed: {
    ...mapState(['StepFive', 'canShareData'], ['StepFive', 'acceptTerms'], ['StepFive', 'acceptPolicy']),

    ...mapActions(['StepFive', 'setCanShareData'], ['StepFive', 'setAcceptTerms'], ['StepFive', 'setAcceptPolicy']),
    ...mapActions(['StepFive', 'setFetchError']),
    ...mapActions(['StepFive', 'setFetchErrorMessage']),

    ...mapGetters(['StepFive', 'canShareData'], ['StepFive', 'acceptTerms'], ['StepFive', 'acceptPolicy']),
    ...mapGetters(['StepFive', 'form']),
    ...mapGetters(['StepFive', 'messageStatus']),
    ...mapGetters(['StepFive', 'fetchError']),
    ...mapGetters(['StepFive', 'fetchErrorMessage']),

    canShareData: {
      get() { return this.$store.getters.canShareData },
      set(newValue) { this.toggleCanShareData(newValue) }
    },
    acceptTerms: {
      get() { return this.$store.getters.acceptTerms },
      set(newValue) { this.$store.dispatch('setAcceptTerms', newValue) }
    },
    acceptPolicy: {
      get() { return this.$store.getters.acceptPolicy },
      set(newValue) { this.$store.dispatch('setAcceptPolicy', newValue) }
    }
  },
  watch: {
    $v: {
      handler: function (val) {
        if(!val.$invalid) {
          this.$emit('can-continue', { value: true })
        } else {
          this.$emit('can-continue', { value: false })
        }
      },
      deep: true
    },
    clickedNext(val) {
      if(val === true) {
        this.$v.form.$touch()
      }
    }
  },
  mounted() {
    if (this.canShareData && this.acceptTerms == 'S') {
      this.$emit('can-continue', { value: true })
    } else {
      this.$emit('can-continue', { value: false })
    }
  },
  activated: function () {
    if (this.canShareData && this.acceptTerms == 'S') {
      this.$emit('can-continue', { value: true })
    } else {
      this.$emit('can-continue', { value: false })
    }
  },
  created: function() {
    this.getData()
    this.acceptTerms = null;
    this.acceptPolicy = null;
    this.canShareData = null;
  },
}
</script>

<style scoped lang="scss">
.radio-list {
  .custom-control {
    display: inline;
  }
}

.alert-centered {
  display: flex;
  align-items: center;
}

.alert-card-info {
  height: 4rem;
  padding: 0.5rem 1.2rem 0rem 1.2rem;
}

@media (max-width: 392px) {
  .alert-card-info {
    height: 5rem;
  }
}
</style>
