<template>
  <div class="row">
    <div class="container">
      <div class="col-sm-12 col-md-10 offset-md-1 mt-5">

        <div class="container">
          <ModalErrorHandler
            :hasFetchError="fetchError"
            :message="fetchErrorMessage"/>
        </div>

        <div class="container mb-4">
          <b-alert dismissible variant="warning" v-model="showMessage">
            <font-awesome-icon icon="exclamation-circle" class="mr-2" size="lg"/>
            {{ message }}
          </b-alert>
        </div>

        <b-form class="container" @submit.stop.prevent>
          
          <input type="hidden" name="square" :value="square">

          <label label-for="card-number">Número do cartão *:</label>
          <b-input-group id="input-group-2" class="mb-4">
            <b-form-input
              required
              type="tel"
              maxlength="15"
              id="card-number"
              autocomplete="off"
              :disabled="loading"
              placeholder="Número do cartão"
              v-model="$v.form.cardNumber.$model"
              :state="validateState('cardNumber')"
              v-on:keypress="validateCardValue($event)"
            ></b-form-input>

            <b-input-group-append v-if="loading" is-text>
              <b-spinner
                small
              ></b-spinner>
            </b-input-group-append>

            <b-form-invalid-feedback :state="validateState('cardNumber')">
              O número do cartão deve possuir 15 caracteres
            </b-form-invalid-feedback>
          </b-input-group>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import { validationMixin } from 'vuelidate'
import { required, minLength, numeric } from 'vuelidate/lib/validators'

import { verifyCard, getCardInfo, getSquare } from '../services/card'

import ModalErrorHandler from './ModalErrorHandler/ModalErrorHandler'


const PRACAS = process.env.PRACA

export default {
  mixins: [validationMixin],
  components: {
    ModalErrorHandler
  },
  data() {
    return {
      squareList: [],
      message: null,
      showMessage: false,
      loading: false,
      questions: [],
      square: null
    }
  },
  methods: {
    validateState(field) {
      if (this.$v.form[field].$model) {
        return this.$v.form[field].$dirty ? !this.$v.form[field].$invalid : null
      }
    },
    validateCardValue(event) {
      let char = String.fromCharCode(event.keyCode)

      if (/^[0-9]+$/.test(char)) return true
      else event.preventDefault()
    },
    getData: async function() {
        
      let praca = await getSquare()
      this.square = praca
      debugger;
    },
    getCardInfo: async function(data, square) {
      let cardInfo = await getCardInfo(data, square)
      return cardInfo
    },
    getSquareName: function(val) {
      let squareName = this.squareList.filter(value => value.sigla == val)[0]
      this.$store.dispatch('setSquareName', squareName.nome)
    }
  },
  validations: {
    form: {
      cardNumber: {
        numeric,
        required,
        minLength: minLength(15)
      },
    }
  },
  computed: {
    ...mapState(['StepOne', 'form']),

    ...mapGetters(['StepOne', 'form']),
    ...mapGetters(['StepOne', 'fetchError']),
    ...mapGetters(['StepOne', 'fetchErrorMessage']),

    ...mapActions(['StepOne', 'setForm']),
    ...mapActions(['StepOne', 'setQuestions']),
    ...mapActions(['StepOne', 'resetState']),
    ...mapActions(['StepOne', 'setSquareName']),
    ...mapActions(['StepOne', 'setFetchError']),
    ...mapActions(['StepOne', 'setFetchErrorMessage']),
    ...mapActions(['StepOne', 'resetForm']),
  },
  watch: {
    $v: {
      handler: async function (val) {

        if(!val.$invalid) {
          this.loading = true

          try {
            let cardData = this.form.cardNumber
            let cardIsValid = await verifyCard(cardData, this.square)

            if (cardIsValid.status == 200) {
              let cardInfo = await this.getCardInfo(cardData, this.square)

              if (cardInfo.status == 200) {

                this.$emit('can-continue', { value: true })

                this.$store.dispatch('setForm', this.form)
                this.$store.dispatch('setQuestions', cardInfo.data.perguntas)

                this.showMessage = false
                this.loading = false

              } else {
                this.$emit('can-continue', { value: false })

                this.loading = false
                this.showMessage = true

                this.message = cardInfo.data.mensagemDeErro

                this.$store.dispatch('resetState')
              }

            } else {
              this.$emit('can-continue', { value: false })

              this.loading = false
              this.showMessage = true
              this.message = cardIsValid.data.mensagemDeErro

              this.$store.dispatch('resetState')
            }
          } catch(e) {
            console.error(e)

            this.loading = false

            this.$store.dispatch('setFetchError', true)
            this.$store.dispatch('setFetchErrorMessage', 'Ocorreu um erro ao validar o cartão. Atualize a página ou tente novamente mais tarde')
          }

        } else {
          this.$emit('can-continue', { value: false })
        }
      },
      deep: true
    },
  },
  created: function() {
    this.getData()
    
    if (this.form.square) {
      this.$store.dispatch('resetForm')
    }
    this.form.cardNumber = ""
  },
  mounted() {
    if (this.form.square && this.form.cardNumber) {
      this.$emit('can-continue', { value: true })
    } else {
      this.$emit('can-continue', { value: false })
    }
  },
  activated: function () {
    if (this.form.square && this.form.cardNumber) {
      this.$emit('can-continue', { value: true })
    } else {
      this.$emit('can-continue', { value: false })
    }
  }
}
</script>

<style lang="scss">
  .radio-list {
    .custom-control {
      display: block;
      margin-top: 2em;

      & + .custom-control {
        margin-left: 0;
        margin-top: 1.2em;
      }
    }
}
</style>