<template>
  <div class="row">
    <div class="container">
      <div class="col-sm-12 col-md-10 offset-md-1 mt-5">

        <div class="container">
          <ModalErrorHandler
            :hasFetchError="fetchError"
            :message="fetchErrorMessage"/>
        </div>

        <form class="container form-options">
          <b-alert show variant="primary" class="flex-row alert-card-info mb-4">
            <p class="ml-2">
              
              <br>
              Número do cartão: <b>{{ form.cardNumber }}</b>
            </p>
          </b-alert>

          <div class="control radio-list">
            <b-form-group :label="thirdQuestion.descricao" :disabled="isLoading">
              <b-form-radio
                v-for="answer in thirdQuestion.respostas"
                v-bind:key="answer.id"
                v-bind:value="answer.id"
                v-model="thirdQuestion.selected">{{ answer.descricao }}</b-form-radio>
            </b-form-group>
          </div>
        </form>

        <div>
          <b-spinner
            class="loading"
            variant="primary"
            v-if="isLoading"
            style="width: 4rem; height: 4rem;"></b-spinner>
        </div>
      </div>
    </div>

    <b-modal id="modal-error" ref="modal-error" hide-footer no-close-on-backdrop>
      <template #modal-title>
        Informação
      </template>

      <div class="d-block text-center text-muted">
        <b-row class="text-center mt-4">
          <div class="col-md-4 col-sm-12">
            <font-awesome-icon icon="exclamation-circle" size="3x"/>
          </div>

          <div class="col-md-8 col-sm-12 text-md-left mt-4 mt-md-0">
            <p>
              {{ message }}
            </p>

            <p v-if="codigoExternoCartao">
              Código externo do cartão: {{ codigoExternoCartao }}
            </p>
          </div>
        </b-row>

        <b-button class="btn-default mt-2" @click="$bvModal.hide('modal-error')">
          Ok
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

import { getSquare, verifyAnswers } from '../services/card'

import ModalErrorHandler from './ModalErrorHandler/ModalErrorHandler'

export default {
  name: 'StepFour',
  props: ['clickedNext', 'currentStep'],
  mixins: [validationMixin],
  components: {
    ModalErrorHandler
  },
  data() {
    return {
      error: false,
      message: null,
      codigoExternoCartao: null,
      isLoading: false,
    }
  },
  validations: {
    thirdQuestion: {
      selected: {
        required
      }
    }
  },
  methods: {
    validateAnswers: async function() {
      debugger;
      let square = await getSquare()

      if (!this.isLoading) {
        this.isLoading = true
      }

      await this.$store.dispatch('setValidateData')

      try {

        let dataValidate = this.dataToValidate
        let answersValid = await verifyAnswers(dataValidate, square)

        if (answersValid.status == 200) {

          //this.error = false
          this.isLoading = false

          this.$root.$emit('data-is-valid', true)

          this.$emit('can-continue', { value: true })
          this.$store.dispatch('setMessageStatus', answersValid.data.message)

        } else {

          this.isLoading = false
          //this.error = true

          this.message = answersValid.data.mensagemDeErro
          this.codigoExternoCartao= answersValid.data.codigoExternoCartao

          this.$root.$emit('data-is-valid', false)

          this.$emit('can-continue', { value: false })

          this.$refs['modal-error'].show()
        }

      } catch (e) {
        console.error(e)

        this.isLoading = false

        this.$store.dispatch('setFetchError', true)
        this.$store.dispatch('setFetchErrorMessage', 'Ocorreu um erro ao validar as respostas. Atualize a página ou tente novamente mais tarde')

        this.$emit('can-continue', { value: false })
      }
    },
    persistData: async function(name, value) {
      try {

        await sessionStorage.setItem(name, value)
      
      } catch (e) {
        console.error(e)
      }
    },
    verifySelectedChange: async function(name, newValue) {
      try {

        let oldValue = await sessionStorage.getItem(name)

        if (oldValue) {
          if (oldValue != newValue) {
            this.$store.dispatch('setChange', true)
            this.$root.$emit('data-is-valid', false)
          }
        }

      } catch (e) {
        console.error(e)
      }
    }
  },
  computed: {
    ...mapState(['StepFour', 'thirdQuestion']),

    ...mapGetters(['StepFour', 'fetchError']),
    ...mapGetters(['StepFour', 'fetchErrorMessage']),

    ...mapActions(['StepFour', 'setSelectedThirdQuestion']),
    ...mapActions(['StepFour', 'setValidateData']),
    ...mapActions(['StepFour', 'setMessageStatus']),

    ...mapGetters(['StepFour', 'dataToValidate']),
    ...mapGetters(['StepFour', 'form']),
    ...mapGetters(['StepFour', 'hasChange']),

    ...mapActions(['StepFour', 'setFetchError']),
    ...mapActions(['StepFour', 'setFetchErrorMessage']),
    ...mapActions(['StepFour', 'setChange'])
  },
  watch: {
    $v: {
      handler: async function (val) {
        if(!val.$invalid) {
          this.verifySelectedChange(
            'thirdQuestionSelected', this.thirdQuestion.selected
          )

          this.$store.dispatch(
            'setSelectedThirdQuestion',
            this.thirdQuestion.selected
          )

          this.persistData('thirdQuestionSelected', this.thirdQuestion.selected)

          this.$emit('can-continue', { value: true })
        }
      },
      deep: true
    },
    clickedNext: async function(val) {
      if (val == true) {
        this.isLoading = true
        await this.validateAnswers()
      }
    }
  },
  mounted() {
    if (!this.$v.$invalid) {
      this.$emit('can-continue', { value: true })
    } else {
      this.$emit('can-continue', { value: false })
    }
  },
  activated: async function () {
    if (this.thirdQuestion.selected) {
      this.$emit('can-continue', { value: true })
    } else {
      this.$emit('can-continue', { value: false })
    }
  }
}
</script>

<style scoped>
.custom-control {
  font-family: monospace !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.alert-card-info {
  height: 4rem;
  padding: 0.5rem 1.2rem 0rem 1.2rem;
}

@media (max-width: 392px) {
  .alert-card-info {
    height: 5rem;
  }
}

.loading {
  position: fixed;
  z-index: 999;
  overflow: show;
  margin: auto;
  top: 4rem;
  left: 0;
  bottom: 0;
  right: 0;
}

.btn-default {
  color: #fff !important;
  border-color: #e3774a !important;
  background: linear-gradient(#f4974c, #e3774a) !important;
}
</style>
