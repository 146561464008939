import axios from 'axios'

const BASE_URL = process.env.VUE_APP_BASE_URL

const OAUTH_URL = process.env.VUE_APP_OAUTH_URL
const OAUTH_USER = process.env.VUE_APP_OAUTH_USER
const OAUTH_PASS = process.env.VUE_APP_OAUTH_PASS

const getFileProtocol = async (cardNumber, code, square) => {
  
  let token = await getToken();
  
  const options = {
    url: `${ BASE_URL }/v1/protocolo/download/${ cardNumber }/${ code }?arquivo=protocolo`,
    crossDomain: true,
    headers: {
      "Access-Control-Allow-Origin": "*",
      'Authorization': `Bearer ${ token }`
    },
    method: 'GET',
    responseType: 'blob'
  }
  
  const response = await axios(options)
    .then(res => res)
    .catch(err => err.response)
  
  return response
}

const getPersonalData = async (cardNumber, code, square) => {
  
  let token = await getToken();

  const options = {
    url: `${ BASE_URL }/v1/protocolo/download/${ cardNumber }/${ code }?arquivo=dados-pessoais`,
    crossDomain: true,
    headers: {
      "Access-Control-Allow-Origin": "*",
      'Authorization': `Bearer ${ token }`
    },
    method: 'GET',
    responseType: 'blob'
  }
  
  const response = await axios(options)
    .then(res => res)
    .catch(err => err.response)
  
  return response
}

const getToken = async () => {
  debugger;
  let data = {
    username : OAUTH_USER,
    password: OAUTH_PASS
  }
  const options = {
    url: OAUTH_URL,
    crossDomain: true,
    headers: {
      'Accept': 'application/json',
      "Access-Control-Allow-Origin": "*"      
    },
    method: 'POST',
    data: data,
  }

  const response = await axios(options)
    .then(res => res)
    .catch(err => err.response)
  
  return response.data.access_token;
}

export {
  getFileProtocol,
  getPersonalData,
  getToken
}
