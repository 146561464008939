<template>
  <div class="row">
    <div class="container">
      <div class="col-sm-12 col-md-10 offset-md-1 mt-5">
        <form class="container form-options">
          <b-alert show variant="primary" class="flex-row alert-card-info mb-4">
            <p class="ml-2">
              
              <br>
              Número do cartão: <b>{{ form.cardNumber }}</b>
            </p>
          </b-alert>

          <div class="control radio-list">
            <b-form-group :label="secondQuestion.descricao">
              <b-form-radio
                v-for="answer in secondQuestion.respostas"
                v-bind:key="answer.id"
                v-bind:value="answer.id"
                v-model="secondQuestion.selected">{{ answer.descricao }}</b-form-radio>
            </b-form-group>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'StepThree',
  props: ['currentStep'],
  mixins: [validationMixin],
  data() {
    return {}
  },
  validations: {
    secondQuestion: {
      selected: {
        required
      }
    }
  },
  methods: {
    canContinue() {
      this.$emit('can-continue', { value: true })
    },
    persistData: async function(name, value) {
      try {

        await sessionStorage.setItem(name, value)
      
      } catch (e) {
        console.error(e)
      }
    },
    verifySelectedChange: async function(name, newValue) {
      try {

        let oldValue = await sessionStorage.getItem(name)

        if (oldValue) {
          if (oldValue != newValue) {
            this.$store.dispatch('setChange', true)
          }
        }

      } catch (e) {
        console.error(e)
      }
    }
  },
  computed: {
    ...mapState(['StepThree', 'secondQuestion']),
    ...mapActions(['StepThree', 'setSelectedSecondQuestion']),
    ...mapGetters(['StepThree', 'secondQuestion']),
    ...mapGetters(['StepThree', 'form']),
    ...mapActions(['StepThree', 'setChange'])
  },
  watch: {
    $v: {
      handler: function (val) {
        if(!val.$invalid) {
          this.verifySelectedChange('secondQuestionSelected', this.secondQuestion.selected)

          this.$store.dispatch(
            'setSelectedSecondQuestion',
            this.secondQuestion.selected
          )

          this.persistData('secondQuestionSelected', this.secondQuestion.selected)

          this.$emit('can-continue', { value: true })
        }
      },
      deep: true
    }
  },
  mounted() {
    if(!this.$v.$invalid) {
      this.$emit('can-continue', { value: true })
    } else {
      this.$emit('can-continue', { value: false })
    }
  },
  activated: function () {
    if (this.secondQuestion.selected) {
      this.$emit('can-continue', { value: true })
    } else {
      this.$emit('can-continue', { value: false })
    }
  }
}
</script>

<style scoped>
.custom-control {
  font-family: monospace !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.alert-card-info {
  height: 4rem;
  padding: 0.5rem 1.2rem 0rem 1.2rem;
}

@media (max-width: 392px) {
  .alert-card-info {
    height: 5rem;
  }
}
</style>
