import Vue from 'vue'
import App from './App.vue'

import router from './router'
import { store } from './store'

import { library } from '@fortawesome/fontawesome-svg-core'

import { 
  faQuestionCircle, faExclamationCircle, faCheckCircle,
  faDownload, faFileDownload, faLock, faUser } from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

library.add(faQuestionCircle)
library.add(faExclamationCircle)
library.add(faCheckCircle)
library.add(faDownload)
library.add(faFileDownload)
library.add(faLock)
library.add(faUser)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
